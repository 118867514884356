import Pagination from '@/component/pagination/index.vue'
import Transfer from '@/page/admin/company-management/staff-management/component/transfer/index.vue'
import delDialog from '@/page/admin/company-management/staff-management/component/del-dialog/index.vue'
import consumer_web_position_addPosition from '@/lib/data-service/haolv-default/consumer_web_position_addPosition'
import consumer_web_position_getPositionList from '@/lib/data-service/haolv-default/consumer_web_position_getPositionList'
import consumer_web_position_delPosition from '@/lib/data-service/haolv-default/consumer_web_position_delPosition'
import consumer_web_position_updatePosition from '@/lib/data-service/haolv-default/consumer_web_position_updatePosition'
import consumer_web_position_addEmployeePositionBinding from '@/lib/data-service/haolv-default/consumer_web_position_addEmployeePositionBinding'
import consumer_department_getDepartmentUsers from '@/lib/data-service/haolv-default/consumer_department_getDepartmentUsers'
import getCompanyInfo from '@/lib/data-service/haolv-default/consumer_web_company_getCompanyInfo' // 获取公司信息

export default {
    data () {
        return {
            loading: false,
            companyInfo: {},
            params: {
                total: 100,
                pageSize: 10,
                currentPage: 1
            },
            tableData: [],
            dialogVisible: false,
            newParams: {
                id: '',
                positionName: ''
            },
            dialogType: 'add', // add = 新增 edit = 编辑
            dialogStatus: {
                add: '新增职位',
                edit: '编辑职位'
            },
            rule: {
                positionName: [
                    { required: true, message: '请输入部门名称', trigger: 'blur' },
                ]
            },
            dialogDelVisible:  false,
            dialogSelectVisible: false,
            searchTransferText: '',
            delItem: {},
            cascadeList: [], // 穿梭框用
            currentActiveList: [], // 当前穿梭框选中的
            changeParams: {
                positionId: 0,
                staffId: []
            }
        }
    },
    components: {
        Pagination,
        Transfer,
        delDialog
    },
    created () {},
    mounted () {

    },
    activated () {
        this.getCompanyData()
        this.getPositionList()
    },
    deactivated() {},
    destroyed () {},
    watch: {
        currentActiveList(newVal) {
            console.log('a', newVal)
        }
    },
    computed: {},
    filters: {},
    methods: {
        getPositionList() {
            this.loading = true
            consumer_web_position_getPositionList(this.params).then(res => {
                this.loading = false
                this.tableData = res.datas.list
                this.params.total = res.datas.totalCount
            }).catch(() => {
                this.tableData = []
                this.loading = false
            })
        },
        getCompanyData() {
            getCompanyInfo().then(res => {
                this.companyInfo = res.datas
            })
        },
        handleAdd() {
            this.reactNewParams()
            this.dialogType = 'add'
            this.dialogVisible = true
            this.$nextTick(() => {
                this.$refs['addForm'].clearValidate()
            })
        },
        submitAdd() {
            this.$refs['addForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    consumer_web_position_addPosition(this.newParams).then(res => {
                        this.loading = false
                        this.$message({
                            message: '新增成功',
                            type: 'success'
                        });
                        this.getPositionList()
                        this.dialogVisible = false
                    }).catch(() => {
                        this.loading = false
                    })
                }
            })
        },
        reactNewParams() {
            this.newParams = {
                positionName: ''
            }
        },
        handleEdit(val) {
            this.reactNewParams()
            this.dialogType = 'edit'
            this.dialogVisible = true
            this.newParams = {
                id: val.id,
                positionName: val.positionName
            }
            this.$nextTick(() => {
                this.$refs['addForm'].clearValidate()
            })
        },
        submitEdit() {
            this.$refs['addForm'].validate((valid) => {
                if (valid) {
                    this.loading = true
                    consumer_web_position_updatePosition(this.newParams).then(res => {
                        this.loading = false
                        this.$message({
                            message: '保存成功',
                            type: 'success'
                        });
                        this.getPositionList()
                        this.dialogVisible = false
                    }).catch(() => {
                        this.loading = false
                    })
                }
            })
        },
        handleDel(val) {
            this.delItem = val
            this.dialogDelVisible = true
        },
        submitDel() {
            this.loading = true
            consumer_web_position_delPosition({id: this.delItem.id}).then(res => {
                this.loading = false
                this.$message({
                    message: '删除成功',
                    type: 'success'
                });
                if (this.tableData.length <= 1 && this.params.currentPage !== 1) {
                    this.params.currentPage--
                }
                this.getPositionList()
                this.dialogDelVisible = false
            }).catch(() => {
                this.loading = false
            })
        },
        async handleSelect(val) {
            let reduceDataFunc = (data) => {
                data.map((m, i) => {
                    m.children = m.deptList
                    m.userList = m.staffList
                    delete m.deptList
                    delete m.staffList
                    if (m.userList && m.userList.length) {
                        m.userList.forEach(value => {
                            value.id = value.subStaffId
                            value.name = value.subStaffName
                        })
                    }
                    if (m.children && m.children.length > 0) {
                        reduceDataFunc(m.children)
                    }
                })
            }

            this.dialogSelectVisible = true
            this.reactChangeParams()
            this.changeParams = {
                positionId: val.id,
                staffId: []
            }
            let result = await consumer_department_getDepartmentUsers({companyId: this.companyInfo.id})
            let cascadeList = result.datas
            reduceDataFunc(cascadeList)
            /*cascadeList.forEach(value => {
                value.userId = value.subUserId
                value.userName = value.subUserName
            })*/
            this.cascadeList = cascadeList

            let staffs = val.staffs
            let currentActiveList = []
            staffs.forEach(value => {
                currentActiveList.push(value.id)
            })
            this.currentActiveList = currentActiveList
        },
        submitChange() {
            console.log(this.currentActiveList)
            this.changeParams.staffId = this.currentActiveList
            this.loading = true
            consumer_web_position_addEmployeePositionBinding(this.changeParams).then(res => {
                this.loading = false
                this.$message({
                    message: '绑定成功',
                    type: 'success'
                });
                this.dialogSelectVisible = false
                this.getPositionList()
            }).catch(() => {
                this.loading = false
            })
        },
        reactChangeParams() {
            this.changeParams = {
                positionId: 0,
                staffId: []
            }
        },
    }
}
/*
* export default {
  data () {
    return {}
  },
  components: {},
  created () {},
  mounted () {},
  activated () {
  },
  deactivated() {},
  destroyed () {},
  watch: {},
  computed: {},
  filters: {},
  methods: {
  }
}
*/
